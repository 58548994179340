.article {
    .article-headline {
        color: $primary;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1;
        margin-bottom: map-get($map: $spacers, $key: lg);
        font-size: 2.25rem;
    }

    p {
        line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: map-get($map: $spacers, $key: md);
        text-transform: none;
        color: $body-color;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 0.75rem;
    }

    .date-time {
        font-weight: 700;
        color: $gray;
    }
}
