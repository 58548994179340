.navbar-header {
    background-color: $white;

    .navbar-brand {
        padding: 0;
    }

    .nav-link {
        --bs-navbar-nav-link-padding-x: 1rem;

        @include media-breakpoint-down($navbar-breakpoint) {
            @include rfs(1.5rem);
        }
    }

    @include media-breakpoint-down($navbar-breakpoint) {
        .navbar-nav {
            .nav-item {
                border-top: solid 1px $gray-500;
            }
        }
    }

    .nav-item,
    .btn {
        text-transform: uppercase;
    }
    .nav-item-cta {
        display: flex;
        align-items: center;
        margin-left: var(--bs-navbar-nav-link-padding-x);

        @include media-breakpoint-down($navbar-breakpoint) {
            padding-top: map-get($map: $spacers, $key: md);
            margin-left: 0;
        }
    }

    .navbar-toggler {
        border: 0;
        color: $body-color;
        border-radius: 0;
    }

    .dropdown-menu,
    .dropdown-menu.show {
        padding: 0;
        top: 100%;
        left: 0;
    }

    .dropdown-menu.show-mobile {
        @include media-breakpoint-down(md) {
            display: block !important;
        }
    }

    .dropdown-menu,
    .dropdown-item {
        background-color: $white;

        @include media-breakpoint-down($navbar-breakpoint) {
            background-color: transparent;
        }
    }

    .dropdown-item:active {
        background-color: $white;
        color: $body-color;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &::after {
            content: '\f078';
            font-family: 'Font Awesome 5 Pro';
            border: 0;
            margin: 0;
        }
    }

    @include media-breakpoint-down($navbar-breakpoint) {
        .dropdown-menu {
            border: 0;
            margin-bottom: map-get($map: $spacers, $key: md);

            .dropdown-item {
                padding-inline: 0;
            }
        }
    }
}
