textarea.form-control {
    min-height: 7.5rem;
}

.form-label {
    margin-bottom: 0;
}

.form-select {
    color: $input-placeholder-color;

    option:not(:first-of-type) {
        color: $body-color;
    }

    option[value=''][disabled] {
        display: none;
    }
}

.form-select,
.form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    &:focus {
        box-shadow: none;
    }
}
