.header.sticky-top {
    .header-logo {
        transition: transform $transition-md;
        transform-origin: left;
        width: 175px;

        @include media-breakpoint-down(md) {
            width: 125px;
        }
    }

    .navbar {
        transition: padding $transition-md;
    }

    &.scrolled {
        .navbar {
            --bs-navbar-padding-y: 0;
        }

        .header-logo {
            transform: scale(0.75);
        }
    }
}
