.hero-banner {
    background-color: $primary;
    color: $white;
    padding: map-get($map: $spacers, $key: lg) 0;

    .container {
        display: flex;
        gap: 3rem;
        align-items: center;

        .text {
            .headline {
                color: $white;
                margin-bottom: map-get($map: $spacers, $key: md);
            }
        }
    }
}
