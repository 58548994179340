.deployment-banner {
    background-color: $primary;
    color: $white;
    padding: map-get($map: $spacers, $key: 3x) 0;

    .inner {
        display: grid;
        gap: 1rem;
        grid-template-columns: 2fr 1fr 1fr 1fr;

        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(3, 1fr);

            .last-deployment {
                grid-column: 1 / -1;
                grid-row: 1;
                margin-bottom: map-get($map: $spacers, $key: 2x);

                &::after {
                    display: none;
                }
            }

            .type {
                grid-row: 2;
                grid-column: 1;
            }

            .location {
                grid-row: 2;
                grid-column: 2;
            }

            .date {
                grid-row: 2;
                grid-column: 3;
            }
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
            max-width: clamp(100px, 70%, 400px);
            margin-inline: auto;

            .last-deployment {
                grid-column: 1 / -1;
                grid-row: 1;
                margin-bottom: map-get($map: $spacers, $key: 2x);

                &::after {
                    display: none;
                }
            }

            .type {
                grid-row: 2;
                grid-column: 1;
            }

            .location {
                grid-row: 3;
                grid-column: 1;
            }

            .date {
                grid-row: 4;
                grid-column: 1;
            }
        }
    }

    .last-deployment {
        position: relative;
        padding-right: calc(2rem + 2px);

        &::after {
            content: '';
            height: 100%;
            width: 2px;
            background-color: $white;
            display: block;
            position: absolute;
            top: 0;
            right: 2rem;
        }
    }

    h3 {
        margin-bottom: map-get($map: $spacers, $key: xs);
        @include rfs(1.75rem);
        font-weight: 700;
    }

    .message {
        font-size: 1rem;
        font-weight: 500;
    }
}
