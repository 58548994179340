.roster-slider,
.roster-list,
.news-event-teaser {
    .card-body {
        padding: map-get($map: $spacers, $key: lg);
    }

    .date {
        margin-bottom: map-get($map: $spacers, $key: xs);
        color: $gray;
        gap: map-get($map: $spacers, $key: sm);
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .card-title {
        @include rfs(1rem);
        font-weight: 700;
        margin-bottom: 0;
    }

    p {
        margin-top: map-get($map: $spacers, $key: md);
    }

    .btn {
        align-self: flex-start;
    }
}
