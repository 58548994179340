.image-banner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .image,
    .container {
        grid-column: 1;
        grid-row: 1;
    }

    .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .container {
        display: flex;
        align-items: center;
    }

    .content {
        color: $white;
        display: grid;
        grid-template-columns: 50%;
        grid-template-rows: auto;
        row-gap: 1rem;
        padding: map-get($map: $spacers, $key: section) 0;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
        }

        .headline {
            color: $white;
        }

        .headline,
        .text p {
            margin-bottom: 0;
        }
    }
}
