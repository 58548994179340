$image-size: 75px;
$inner-padding: map-get(
    $map: $spacers,
    $key: lg,
);

.about-slider {
    position: relative;

    @include media-breakpoint-down(md) {
        grid-row: 2;
    }

    .about-card {
        background-color: $white;
        display: flex;
        flex-direction: column;
        flex: 1;

        .body {
            flex-grow: 1;
            padding: $inner-padding;
            line-height: 1.75;

            i {
                font-size: 2.125rem;
                color: $primary;
                line-height: 1;
                font-style: italic;
                margin-bottom: map-get($map: $spacers, $key: sm);
            }

            p {
                &:only-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .footer {
            padding-inline: $inner-padding;
            padding-bottom: $inner-padding;
            margin-top: calc($image-size / 2);
            background-color: $primary;
            color: $white;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;

            .rounded-image {
                margin-top: calc($image-size / 2 * -1);
                margin-bottom: map-get($map: $spacers, $key: md);
                height: $image-size;
                width: $image-size;
                border-radius: 50%;
                background-color: $primary;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 2px $white;

                img {
                    margin-top: 20px;
                }
            }

            .name {
                font-weight: 700;
            }
        }
    }
}
