.vehicle-card {
    .card-body {
        padding: map-get($map: $spacers, $key: lg);
        background-color: $white;
        transition: background-color $transition-md;

        .card-title {
            @include rfs(1rem);
            transition: color $transition-md;
            font-weight: 700;
            color: $primary;
            margin-bottom: map-get($map: $spacers, $key: lg);
        }

        .card-subtitle {
            @include rfs(0.9rem);
            transition: color $transition-md;
            font-weight: 700;
            color: $gray;
        }

        .icon-nav .nav-link {
            font-size: 14px;
            transition: color $transition-md;

            &::before {
                color: $primary;
                font-weight: 700;
                transition: color $transition-md;
            }
        }
    }

    &:hover {
        .card-body {
            background-color: $primary;

            .card-title {
                color: $white;
            }

            .icon-nav .nav-link::before,
            .icon-nav .nav-link {
                color: $white;
            }
        }
    }
}
