.career-path {
    overflow: hidden;

    .headline {
        text-transform: none;
    }

    .inner {
        display: grid;
        grid-template-columns: 1fr 2fr;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            grid-template-rows: 250px 1fr;
        }

        &.right {
            grid-template-columns: 2fr 1fr;

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
                grid-template-rows: 250px 1fr;
            }

            .image {
                grid-column: 2;

                @include media-breakpoint-down(md) {
                    grid-column: 1;
                    grid-row: 1;
                    position: relative;
                    height: 400px;
                    object-fit: cover;
                }
            }

            .content {
                grid-column: 1;

                @include media-breakpoint-down(md) {
                    grid-column: 1;
                    grid-row: 2;
                }
            }
        }

        .image {
            grid-column: 1;
            grid-row: 1;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: top center;
            }

            @include media-breakpoint-down(md) {
                position: relative;
                height: 400px;
                object-fit: cover;
            }
        }

        .content {
            grid-column: 2;
            grid-row: 1;
            background-color: $white;
            padding: map-get($map: $spacers, $key: 2x);
            position: relative;
            z-index: 1;

            @include media-breakpoint-down(md) {
                grid-column: 1;
                grid-row: 2;
            }

            .grid-table {
                display: grid;
                grid-template-columns: repeat(auto);
                grid-template-rows: auto;
                grid-template-areas:
                    'header'
                    'body';

                .grid-content {
                    grid-area: header;
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    gap: map-get($map: $spacers, $key: 2x);

                    .grid-header {
                        color: $primary;
                        font-weight: 700;
                        padding-bottom: map-get($map: $spacers, $key: lg);
                    }

                    .grid-column {
                        &:nth-child(odd) {
                            font-weight: 700;
                        }

                        ul {
                            list-style: none;
                            padding-left: 0;
                            margin-bottom: 0;
                            display: flex;
                            flex-direction: column;
                            gap: map-get($map: $spacers, $key: md);
                        }
                    }
                }
            }
        }
    }
}
