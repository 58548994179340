.image-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    &.image-text-small {
        grid-template-columns: auto 1fr;

        .image {
            justify-content: flex-start;
        }
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: none;
        grid-template-row: 1fr 1fr;
        gap: 1rem;
    }

    .image {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(md) {
            grid-row: 2;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        @include media-breakpoint-down(md) {
            grid-row: 1;
        }

        .headline {
            color: $primary;
            // font-size: 2.25rem;
            margin-bottom: 0;
        }

        .text p {
            margin-bottom: 0;
            line-height: $line-height-lg;
        }
    }
}
