.employee-card {
    display: flex;
    flex-direction: column;

    .image {
        background-color: $white;
        position: relative;
        display: flex;
        justify-content: center;

        img {
            position: relative;
            z-index: 2;
        }

        &::before {
            content: '';
            display: block;
            height: 70%;
            background-color: $primary;
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    .footer {
        flex: 1;
        background-color: $white;
        padding: map-get($map: $spacers, $key: lg);
        text-align: center;

        .name {
            color: $primary;
            @include rfs(1.15rem);
            font-weight: 700;
        }

        .title {
            font-size: 1rem;
            font-weight: 500;
        }
    }
}
