.swiper-container {
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 2rem;
    }
}

.swiper {
    .swiper-slide {
        height: auto;
        display: flex;
    }
}

.swiper-button {
    position: absolute;
    top: 50%;
    cursor: pointer;

    &.swiper-button-disabled {
        opacity: 0.5;
        cursor: default;
    }

    i {
        color: $primary;
        font-size: 2rem;
    }

    &-prev {
        left: 0;
        transform: translate(-140%, -50%);

        @include media-breakpoint-down(md) {
            transform: translate(0, -50%);
        }
    }

    &-next {
        right: 0;
        transform: translate(140%, -50%);

        @include media-breakpoint-down(md) {
            transform: translate(0, -50%);
        }
    }
}
