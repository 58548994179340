.accordion {
    background-color: $white;

    .accordion-item {
        border-inline: 0;
    }

    .accordion-button {
        font-weight: 700;
        padding-inline: 0;
        @include rfs(1.25rem);

        @include media-breakpoint-down(sm) {
            padding-inline: $accordion-button-padding-x;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: $white;
    }

    .accordion-collapse.show {
        background-color: $white;
    }
}
