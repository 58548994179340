// ** List with Font Awesome Icons
@mixin fa-pseudo-element($icon) {
    &::before {
        content: $icon;
        position: absolute;
        left: 0;
        top: var(--bs-nav-link-padding-y);
        font-family: 'Font Awesome 5 Pro';
        margin-right: 0.5rem;
    }
}

.icon-nav {
    .nav-item {
        .nav-link {
            position: relative;
            padding-left: 1.625rem;
        }
    }
}

.icon-email {
    @include fa-pseudo-element('\f0e0');
}

.icon-phone {
    @include fa-pseudo-element('\f095');
}

.icon-location {
    @include fa-pseudo-element('\f3c5');
}

.icon-calendar {
    @include fa-pseudo-element('\f073');
}

.icon-truck {
    @include fa-pseudo-element('\f0d1');
}

.icon-walkie-talkie {
    @include fa-pseudo-element('\f8ef');
}

.icon-tag {
    @include fa-pseudo-element('\f02b');
}

.btn-row {
    gap: 1rem;
}

// TODO: use Nav height
.anchor {
    scroll-margin-top: 4.5rem;
}

.no-hover {
    &:hover {
        color: inherit;
    }
}

p {
    &:only-child,
    &:last-child {
        margin-bottom: 0;
    }
}

.required::after {
    content: '*';
    color: inherit;
}
