// Base Colors
$primary: #d32525;
$secondary: #2c2c2c;
$gray: #898989;
$lightgray: #f5f5f5;
$white: #ffffff;
$black: #000000;

// Image Position Values
$imagePositions: (
    left: left,
    leftTop: left top,
    leftBottom: left bottom,
    right: right,
    rightTop: right top,
    rightBottom: right bottom,
    top: top,
    middle: center,
    bottom: bottom,
);

$custom-theme-colors: (
    'gray': $gray,
    'filter': $lightgray,
);

$body-bg: #f8f8f8;

// Container Max Widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1032px,
);

// Spacers
$spacer: 1rem;
$spacers: (
    0: 0,
    xs: $spacer * 0.25,
    sm: $spacer * 0.5,
    md: $spacer,
    lg: $spacer * 1.5,
    2x: $spacer * 2,
    3x: $spacer * 3,
    4x: $spacer * 4,
    5x: $spacer * 5,
    6x: $spacer * 6,
    7x: $spacer * 7,
    8x: $spacer * 8,
    9x: $spacer * 9,
    10x: $spacer * 10,

    section: $spacer * 6,
);

$grid-gutter-width: $spacer;

// CSS Grid
$enable-cssgrid: false;

// Body
$body-color: $secondary;

// Buttons
$btn-color: $white;
$btn-hover-color: $white;
$btn-border-radius: 50rem;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1.375rem;
$btn-line-height: 1.2;

$btn-filter-color-default: #ebebeb;
$btn-filter-color-active: $primary;

// Input
$input-bg: $white;
$input-border-color: $white;
$input-placeholder-color: $black;
$input-btn-padding-x: $spacer * 1.5;

// Links
$link-color: $body-color;

// Navbar
$navbar-breakpoint: 'xl';
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-focus-width: 0;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
// $navbar-padding-y: 0.5rem;
// $navbar-padding-x: 1rem;
$navbar-nav-link-padding-x: 1rem;

// Nav Item
// $nav-link-padding-y: 0.5rem;
// $nav-link-padding-x: 2rem;
$nav-link-font-size: 1rem;
$nav-link-font-weight: 500;
$nav-link-color: $secondary;
$nav-link-hover-color: $primary;

// Border Radius
$border-radius: 0.625rem;
$border-radius-sm: 1.25rem;
$border-radius-lg: 2.5rem;
$border-radius-xl: 5rem;
$border-radius-xxl: 10rem;

// Font Family
$font-family-sans-serif:
    'Lexend Deca',
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    'Noto Sans',
    'Liberation Sans',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Noto Color Emoji';

// Font Size
$h1-font-size: 2.75rem;
$h2-font-size: 2.25rem;
$h3-font-size: 2rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1.25rem;

$font-size-base: 0.875rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$line-height-base: 1.5;
$line-height-sm: 1;
$line-height-lg: 1.75;

$headline-size-base: 2.25rem;
$headline-size-sm: 1.25rem;
$headline-size-lg: 3.75rem;

// Font Weight
$headings-font-weight: 400;

// Transition
$transition-md: 0.5s ease-in-out;
$transition-lg: 1s ease-in-out;
$transition-xl: 1.5s ease-in-out;

// Cards
$card-border-width: 0;
$card-border-radius: 0;
$card-inner-border-radius: 0;
$card-cap-bg: $white;

// Accordion
$accordion-button-active-bg: transparent;
$accordion-border-color: #c7c7c7;
$accordion-button-focus-border-color: transaperent;
$accordion-button-focus-box-shadow: transaperent;
$accordion-border-radius: 0;
$accordion-bg: $white;
// $accordion-button-padding-x: 0;

// Forms
$input-border-radius: 0;
$input-bg: transparent;
$input-placeholder-color: #c7c7c7;
$input-border-color: #707070;
$input-padding-y: 0.625rem;
$input-padding-x: 0.625rem;
$input-focus-border-color: $input-border-color;
$input-box-shadow: 0;
$input-focus-box-shadow: 0;

// Dropdown
$dropdown-border-radius: 0;
$dropdown-item-padding-y: $spacer * 0.5;
$dropdown-item-padding-x: $spacer * 1.5;
$dropdown-link-active-color: $primary;
