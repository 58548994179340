body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        flex-grow: 1;
        overflow-x: hidden;
    }
}

a[href^='mailto:'],
a[href^='tel:'] {
    text-decoration: none;
}

@each $position, $value in $imagePositions {
    .image-#{$position} {
        object-position: $value;
    }
}
