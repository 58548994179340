.facts-banner {
    background-color: $primary;
    padding: calc(#{map-get($map: $spacers, $key: section)} / 2);

    .container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 3rem;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
            justify-self: flex-start;
        }

        .fact {
            text-align: center;
            color: $white;

            .number {
                @include rfs(3.75rem);
                font-weight: 700;
                line-height: 1.25;
            }

            .text {
                font-weight: 500;
            }
        }
    }
}
