.hero {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .image,
    .container {
        grid-column: 1;
        grid-row: 1;
    }

    .container {
        display: flex;
    }

    .image img {
        width: 100%;
        height: 650px;
        // height: clamp(100%, 650px, 70vh);
        object-fit: cover;
    }

    @include media-breakpoint-down($navbar-breakpoint) {
        .image img {
            min-height: 400px;
            height: 60vh;
        }
    }
}

.hero-home {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        grid-template-areas: 'content images';

        @include media-breakpoint-down(md) {
            max-width: none;
            padding: 0;
            grid-template-columns: 1fr;
            grid-template-areas: 'hero';
        }

        .content {
            align-self: flex-end;
            margin-bottom: 27%;
            color: $white;
            grid-area: content;
            z-index: 3;

            @include media-breakpoint-down(md) {
                grid-area: hero;
                width: 60vw;
                justify-self: center;
                align-self: center;
                margin-bottom: 0;
            }

            .headline {
                @include rfs(3.75rem);
                color: $white;
                font-weight: 900;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: map-get($map: $spacers, $key: lg);
            }

            .text {
                font-weight: 400;
                margin-bottom: map-get($map: $spacers, $key: lg);
            }
        }

        .employee-images {
            grid-area: images;
            grid-template-columns: 1fr 100px 1fr;
            display: grid;

            @include media-breakpoint-down(md) {
                display: none;
                grid-template-columns: 1fr 1fr;
                grid-area: hero;
            }

            img {
                align-self: flex-end;
                grid-row: 1;

                &:first-child {
                    grid-column: 1 / 3;
                    z-index: 1;
                    justify-self: flex-end;

                    @include media-breakpoint-down(md) {
                        grid-column: 1;
                        justify-self: flex-start;
                    }
                }

                &:last-child {
                    grid-column: 2 / -1;
                    z-index: 2;

                    @include media-breakpoint-down(md) {
                        grid-column: 2;
                        justify-self: flex-end;
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.hero-employees {
    --overlap: 70px;

    .container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0;

        .employee-images {
            display: grid;
            grid-template-columns: auto var(--overlap) auto var(--overlap) auto;

            img {
                grid-row: 1;
                align-self: flex-end;
                max-height: 55vh;
                min-height: 300px;
                width: 100%;
                object-fit: cover;

                &:nth-child(1) {
                    grid-column: 1 / 3;
                    z-index: 1;
                }

                &:nth-child(2) {
                    grid-column: 2 / 5;
                    z-index: 2;
                }

                &:nth-child(3) {
                    grid-column: 4 / 6;
                    z-index: 1;
                }
            }
        }
    }
}

.hero-headline {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
        }
    }

    .headline-container {
        text-align: center;
        grid-column: 1;
        grid-row: 1;
        transform: rotate(-7deg);

        .headline {
            display: inline;
            margin-bottom: 0;
            background-color: $white;
            color: $primary;
            @include rfs(4.75rem);
            font-weight: 900;
            padding: 0 0.625rem;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }
    }
}
