.headline {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    color: $primary;

    &.sm {
        margin-bottom: map-get($map: $spacers, $key: lg);
    }

    &.md {
        margin-bottom: map-get($map: $spacers, $key: 3x);
    }

    .lg {
        margin-bottom: map-get($map: $spacers, $key: 4x);
    }
}

.headline-sm {
    @include rfs(1.5625rem);
}

.headline-md {
    @include rfs(2.25rem);
}

.headline-lg {
    @include rfs(3.75rem);
}
