.footer-main {
    padding-top: map-get($map: $spacers, $key: section);
    padding-bottom: map-get($map: $spacers, $key: section);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;

    @include media-breakpoint-down(lg) {
        padding-top: 0;
        padding-bottom: 0;
        background-image: none !important;

        .container {
            padding: 0;
        }
    }

    .footer-nav-headline {
        margin-bottom: map-get($map: $spacers, $key: md);
        font-weight: 700;
        @include rfs(1.5rem);
    }

    .nav-item {
        --bs-nav-link-padding-x: 0;
        --bs-nav-link-padding-y: 0.5rem;
        --bs-nav-link-color: $white;

        line-height: 1.5;
        white-space: nowrap;
    }

    .nav-link {
        white-space: nowrap;
    }

    .emergency-call {
        position: relative;

        .header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: map-get($map: $spacers, $key: md);

            .icon {
                font-size: 3rem;
                margin-right: 0.5rem;
            }

            .headline {
                line-height: 1;
                font-weight: 900;
                font-size: 4rem;
                margin-bottom: 0;
                color: $white;
            }
        }
    }
}

.footer-copyright {
    background-color: $white;
    padding-top: map-get($map: $spacers, $key: md);
    padding-bottom: map-get($map: $spacers, $key: md);
    color: $gray;
    font-weight: 300;

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'copyright legal logos';

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-template-areas:
                'logos'
                'legal'
                'copyright';
            gap: 1rem;
        }

        .copyright {
            grid-area: copyright;
            justify-self: start;
            align-self: center;
            margin-bottom: 0;

            @include media-breakpoint-down(lg) {
                justify-self: center;
            }
        }

        .nav {
            grid-area: legal;
            justify-self: center;
            align-self: center;

            .nav-item {
                --bs-nav-link-color: $gray;
                --bs-nav-link-font-weight: 300;
            }
        }

        .logos {
            grid-area: logos;
            justify-self: end;
            align-self: center;

            @include media-breakpoint-down(lg) {
                justify-self: center;
            }
        }
    }
}
