/* lexend-deca-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 300;
    src: url('../../../../public/fonts/lexend-deca-v21-latin-300.woff2')
        format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 400;
    src: url('../../../../public/fonts/lexend-deca-v21-latin-regular.woff2')
        format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 500;
    src: url('../../../../public/fonts/lexend-deca-v21-latin-500.woff2')
        format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 700;
    src: url('../../../../public/fonts/lexend-deca-v21-latin-700.woff2')
        format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 800;
    src: url('../../../../public/fonts/lexend-deca-v21-latin-800.woff2')
        format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
