// Variant solid
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color == 'primary' {
            @include button-variant(
                $background: $primary,
                $color: $white,
                $hover-color: $primary,
                $border: $primary,
                $hover-border: $primary,
                $hover-background: $white
            );
        } @else if $color == 'secondary' {
            @include button-variant(
                $background: $white,
                $color: $primary,
                $hover-color: $white,
                $border: $white,
                $hover-border: $primary,
                $hover-background: $primary
            );
        } @else if $color == 'filter' {
            @include button-variant(
                $background: $btn-filter-color-default,
                $color: $primary,
                $hover-color: $primary,
                $border: $btn-filter-color-default,
                $hover-border: $primary,
                $hover-background: $white
            );
        }
    }
}

// Variant outline
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant(
            $value,
            $color-hover: $btn-color,
            $active-background: $value,
            $active-border: $value,
            $active-color: $btn-color
        );
    }
}

.btn {
    font-weight: 700;
}

.link.with-icon .btn {
    &::after {
        content: '\f178';
        font-family: 'Font Awesome 5 Pro';
        padding-left: map-get($map: $spacers, $key: xs);
    }
}
