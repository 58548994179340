$timeline-line-width: 3px;
$bullet-size: 9px;

.timeline {
    position: relative;

    // Main Line
    &::before {
        content: '';
        height: 100%;
        width: $timeline-line-width;
        top: 0;
        left: 50%;
        background-color: #d5d5d5;
        position: absolute;
        transform: translateX(-50%);
    }

    // First Bullet
    &::after {
        content: '';
        position: absolute;
        transform: translateX(-50%);
        height: $bullet-size;
        width: $bullet-size;
        background-color: #d5d5d5;
        border-radius: 50%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .timeline-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'left right';
        column-gap: calc(10rem + #{$timeline-line-width});
        padding: 5rem 0;
        position: relative;

        // Red Line
        // &:first-child::before {
        //     content: '';
        //     height: 100%;
        //     width: $timeline-line-widht;
        //     top: 0;
        //     left: 50%;
        //     background-color: $primary;
        //     position: absolute;
        //     z-index: 1;
        // }

        // Bullet
        &::after {
            content: '';
            position: absolute;
            height: $bullet-size;
            width: $bullet-size;
            background-color: #d5d5d5;
            border-radius: 50%;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .image {
            grid-area: left;
        }

        .content {
            grid-area: right;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .date {
                @include rfs(2.25rem);
                color: $primary;
                font-weight: 700;
                margin-bottom: map-get($map: $spacers, $key: lg);
            }
        }

        &:nth-child(even) {
            .image {
                grid-area: right;
            }

            .content {
                grid-area: left;
            }
        }
    }
}
